import Vue from "vue";
import moment from "moment";
import store from "../store/index";

/*
 * format - is a moment format string
 */
Vue.filter("formatDate", function(value, format = null, locale = "en") {
  if (store.state && store.state.language) {
    locale = store.state.language;
  }
  if (!format) {
    moment().locale(window.navigator.language);
    format = "L";
  }
  if (value && value !== "") {
    let formatDate = moment(String(value))
      .locale(locale)
      .format(format);
    if (formatDate === "Invalid date") {
      // Fix to firefox
      formatDate = moment(String(value), format)
        .locale(locale)
        .format(format);
    }
    return formatDate;
  } else {
    return "";
  }
});

/*
 * humanize date: 2021-09-21T09:47:51Z -> an hour ago
 */
Vue.filter("humanize", function(value, locale = "en") {
  // TODO update locale based on input
  if (store.state && store.state.language) {
    locale = store.state.language;
  }
  if (value && value !== "") {
    return moment(String(value))
      .locale(locale)
      .fromNow();
  } else {
    return "";
  }
});

/*
 * humanizeNew date: 2021-09-21T09:47:51Z -> an hour ago and after 5 days show MMM dd, YYYY format
 */
Vue.filter("humanizeNew", function(value, locale = "en") {
  if (store.state && store.state.language) {
    locale = store.state.language;
  }

  if (value && value !== "") {
    const momentDate = moment(String(value)).locale(locale);
    const now = moment().max();

    // Calculate the difference between the date and now in terms of both date and time
    const diff = now.diff(momentDate);
    // const daysDifference = now.diff(momentDate, "days");

    if (diff <= 5 * 24 * 60 * 60 * 1000) {
      // If the date is within 5 days, show it as "X days ago"
      return momentDate.fromNow();
    } else {
      // If the date is older than 5 days, format it as "MMM dd, YYYY"
      return momentDate.format("MMM DD, YYYY");
    }
  } else {
    return "";
  }
});

/*
 * truncate text
 */
Vue.filter("truncate", function(text, length, clamp) {
  clamp = clamp || "...";
  var node = document.createElement("div");
  node.innerHTML = text;
  var content = node.textContent;
  return content.length > length ? content.slice(0, length) + clamp : content;
});
