import axios from "axios";
import i18n from "../../i18next";
import initializeBot from "../../bot-rasa.js";
import utils from "@/router/utils.js";

axios.defaults.headers.common["Accept-Language"] = "en";

export default {
  // is this 'getAllCourses' method used somewhere? I does not seem so
  getAllCourses({ commit, state }) {
    return axios
      .get(`${state.config.baseURL}/courses/discovery`)
      .then(response => {
        commit("GET_ALL_COURSES", {
          data: response.data.data,
          includingSemanticResults: false
        });
      })
      .catch(err => {
        commit("SET_ERRORS", err.response.data);
      });
  },
  getAPublicCourse({ commit, state }, data) {
    return axios
      .get(`${state.config.baseURL}/no-auth/courses/${data.course_id}`)
      .then(response => {
        commit("SET_SELECTED_COURSE", response.data.data);
        commit("SET_SERVER_TIME", response.data.data.current_server_time);
        commit("SET_PAGE_NOT_FOUND_FLAG", false);
      })
      .catch(err => {
        commit("SET_SELECTED_COURSE", {});
        commit("SET_ERRORS", err.response.data);
        commit("SET_PAGE_NOT_FOUND_FLAG", true);
      });
  },
  getAPublicPartnerCourse({ commit, state }, data) {
    // TODO update public partner course detail url
    return axios
      .get(
        `${state.config.baseURL}/gateway/partner-courses/${data.course_id}/open/`
      )
      .then(response => {
        commit("SET_SELECTED_COURSE", response.data);
      })
      .catch(err => {
        commit("SET_SELECTED_COURSE", {});
        commit("SET_ERRORS", err.response.data);
      });
  },
  getDiscoveryCourses({ commit, state }, data) {
    return axios
      .get(
        `${state.config.baseURL}/courses/discovery-paginated?page=${data.currentPage}&page_size=${data.perPage}`
      )
      .catch(err => {
        commit("SET_SELECTED_COURSE", {});
        commit("SET_ERRORS", err.response.data);
      });
  },
  getACourse({ commit, state }, data) {
    let headers = {
      Authorization: `Bearer ${this._vm.$keycloak.token}`
    };
    if (data.version === "v2") {
      headers.Accept = "application/json;version=2.0";
      headers.Openedxcookie = JSON.stringify(state.sessionCookie);
    }
    return axios
      .get(`${state.config.baseURL}/courses/${data.course_id}`, {
        headers: headers
      })
      .then(response => {
        commit("SET_SELECTED_COURSE", response.data.data);
        commit("SET_SERVER_TIME", response.data.current_server_time);
      })
      .catch(err => {
        commit("SET_SELECTED_COURSE", {});
        commit("SET_ERRORS", err.response.data);
      });
  },
  getPartnerCourseDetail({ commit, state }, data) {
    let headers = {
        headers: {
          Authorization: `Bearer ${this._vm.$keycloak.token}`
        }
      },
      URL = `${state.config.baseURL}/gateway/partner-courses/${data.course_id}/`;
    return axios
      .get(URL, headers)
      .then(response => {
        commit("SET_SELECTED_COURSE", response.data);
        // TODO add it post backend updation
        // commit("SET_SERVER_TIME", response.data.current_server_time);
      })
      .catch(err => {
        commit("SET_SELECTED_COURSE", {});
        commit("SET_ERRORS", err.response.data);
      });
  },
  enrollCourse({ commit, state }, course_id) {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${state.config.baseURL}/courses/${course_id}/enroll`,
          {},
          {
            headers: {
              Authorization: `Bearer ${this._vm.$keycloak.token}`
            }
          }
        )
        .then(response => {
          commit(
            "UPDATE_SELECTED_COURSE_ENROLLMENT_STATUS",
            response.data.success
          );
          resolve(response.data.data);
        })
        .catch(err => {
          commit("SET_ERRORS", err.response.data);
          reject(err.response.data);
        });
    });
  },
  exchangeSessionCookie({ commit, state }, addNoCacheHeader = false) {
    let headers = {
      Authorization: `Bearer ${this._vm.$keycloak.token}`
    };
    if (addNoCacheHeader === true) {
      headers["cache-control"] = "no-cache";
    }

    return axios
      .get(`${state.config.baseURL}/auth/exchange_session_cookie/`, {
        headers: headers
      })
      .then(response => {
        commit("SET_SESSION_COOKIE", response.data.cookies);
      })
      .catch(err => {
        commit("SET_ERRORS", err.response.data);
      });
  },
  getUnitsBySequenceId({ commit, state }, sequenceId) {
    return axios
      .get(`${state.config.baseURL}/courses/sequence/${sequenceId}`, {
        headers: {
          Authorization: `Bearer ${this._vm.$keycloak.token}`,
          Accept: "application/json;version=2.0",
          Openedxcookie: JSON.stringify(state.sessionCookie)
        }
      })
      .then(response => {
        commit("SET_UNITS_BY_SEQUENCE_ID", response.data.data);
      })
      .catch(err => {
        commit("SET_UNITS_BY_SEQUENCE_ID", []);
        commit("SET_ERRORS", err.response.data);
      });
  },
  getUserStatus({ commit, state }) {
    return axios
      .get(`${state.config.baseURL}/my-status`, {
        headers: {
          Authorization: `Bearer ${this._vm.$keycloak.token}`
        }
      })
      .then(response => {
        let status = response.data.data.new_user;
        commit("SET_USER_STATUS", status);
        commit("SET_SHOW_COMPLETE_PROFILE", status);
      })
      .catch(err => {
        commit("SET_ERRORS", err.response.data);
      });
  },
  getOnboardingPendingStatus({ commit, state }) {
    return axios
      .get(`${state.config.baseURL}/profile`, {
        headers: {
          Authorization: `Bearer ${this._vm.$keycloak.token}`
        }
      })
      .then(response => {
        let data = response.data.data;
        let pending = false;
        //check data if completed or not
        const propsToCheck = [
          "country",
          "city",
          "date_of_birth",
          "gender",
          "affiliation",
          "experience"
        ];
        propsToCheck.every(p => {
          const propData = data[p];
          const propType = typeof propData;
          if (
            !pending &&
            (propData === null ||
              propData === undefined ||
              propType === "undefined" ||
              (propType === "object" && propData.id <= 0) ||
              (propType === "string" && propData.trim() === ""))
          ) {
            pending = true;
            return false;
          }
          return true;
        });
        localStorage.setItem("onboardingStatus", pending);
        commit("SET_ONBOARDING_PENDING_STATUS", pending);
      })
      .catch(err => {
        commit("SET_ERRORS", err.response.data);
      });
  },
  getAllConstants({ commit, state }) {
    return axios
      .get(`${state.config.baseURL}/constants?list=all`, {
        headers: {
          "Accept-Language": state.language
        }
      })
      .then(response => {
        let data = response.data["data"];
        commit("SET_CONSTANTS", data);
      })
      .catch(err => {
        commit("SET_ERRORS", err.response.data);
      });
  },
  getUserAuthProfile({ commit, state, dispatch, rootState }) {
    return axios
      .get(`${state.config.baseURL}/auth/profile`, {
        headers: {
          "Accept-Language": state.language,
          Authorization: `Bearer ${this._vm.$keycloak.token}`
        }
      })
      .then(response => {
        commit("SET_USER_AUTH_PROFILE", response.data.data);
        // function allows us to check if the user has updated the  language.
        // if updated, language is updated in the server
        if (state.language !== response.data.data.language) {
          dispatch("updateProfile", { language: state.language });
          commit("SET_DOINIT_BOT", false);
        }
        initializeBot(
          state.config,
          response.data.data,
          rootState.doInitBot,
          rootState.chatbotLanguages,
          () => this._vm.$keycloak.token,
          i18n
        );
      })
      .catch(err => {
        commit("SET_ERRORS", err.response.data);
      });
  },
  getAllCredentials({ commit, state }) {
    return axios
      .get(`${state.config.baseURL}/certificates`, {
        headers: {
          "Accept-Language": state.language,
          Authorization: `Bearer ${this._vm.$keycloak.token}`
        }
      })
      .then(response => {
        commit("GET_ALL_CREDENTIALS", response.data.data);
      })
      .catch(err => {
        commit("SET_ERRORS", err.response.data);
      });
  },
  // TODO refactor this post release
  getEnrolledCourses({ commit, state }) {
    return axios
      .get(`${state.config.baseURL}/courses/enrolled?page_size=10`, {
        headers: {
          Authorization: `Bearer ${this._vm.$keycloak.token}`
        }
      })
      .then(response => {
        commit("SET_SERVER_TIME", response.data.current_server_time);
        commit("SET_ENROLLED_COURSES", response.data.results);
      })
      .catch(err => {
        commit("SET_ERRORS", err.response.data);
      });
  },
  postCourseCertificate({ commit, state }, course_id) {
    return axios
      .post(
        `${state.config.baseURL}/certificates/${course_id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${this._vm.$keycloak.token}`,
            Accept: "application/json;version=2.0",
            Openedxcookie: JSON.stringify(state.sessionCookie)
          }
        }
      )
      .catch(err => {
        commit("SET_ERRORS", err.response.data);
      });
  },
  getCourseOverview({ commit, state }, data) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${state.config.baseURL}/courses/overview/${data.course_id}/live/`)
        .then(response => {
          commit("GET_COURSE_OVERVIEW", response.data);
          resolve(response);
        })
        .catch(err => {
          commit("SET_ERRORS", err.response.data);
          reject(err);
        });
    });
  },
  getMaintenanceStatus({ commit, state }) {
    return axios
      .get(`${state.config.baseURL}/api-status`)
      .then(response => {
        if (response.status !== 503) {
          commit("SET_SHOW_MAINTENANCE_FLAG", false);
        }
      })
      .catch(err => {
        if (err.status === 503) {
          commit("SET_SHOW_MAINTENANCE_FLAG", true);
        }
      });
  },
  configureMfa({ state }) {
    return new Promise((resolve, reject) => {
      axios
        .put(
          `${
            state.config.baseURL
          }/auth/configure-mfa?redirect_uri=${utils.getRedirectUri()}`,
          {},
          {
            headers: {
              Authorization: `Bearer ${this._vm.$keycloak.token}`
            }
          }
        )
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  getRandomCourses({ commit, state }, number_of_courses) {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${state.config.baseURL}/courses/random?language=${state.language}&num_courses=${number_of_courses}`
        )
        .then(res => {
          resolve(res.data.data);
        })
        .catch(err => {
          reject(err);
          commit("SET_ERRORS", err.response.data);
        });
    });
  },
  getRecommendedCourses({ state }, number_of_courses) {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${state.config.baseURL}/recommendations?num_courses=${number_of_courses}`,
          {
            headers: {
              Authorization: `Bearer ${this._vm.$keycloak.token}`
            }
          }
        )
        .then(res => {
          resolve(res.data.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  getUserRegistrationStatus({ commit, state }) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${state.config.baseURL}/account/registration`, {
          access_token: this._vm.$keycloak.token
        })
        .then(response => {
          let status = response.data.data.is_new_user;
          commit("SET_USER_STATUS", status);
          commit("SET_SHOW_COMPLETE_PROFILE", status);
          resolve();
        })
        .catch(err => {
          commit("SET_ERRORS", err.response.data);
          reject();
        });
    });
  },
  getSystemAlertStatus({ commit, state }) {
    let url = `${state.config.baseURL}/heartbeat`;
    axios
      .get(url)
      .then(res => {
        commit("SET_SYSTEM_ALERT", res.data);
      })
      .catch(err => {
        commit("SET_ERRORS", err.response.data);
      });
  }
};
