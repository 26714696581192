<template>
  <b-toast
    :id="id"
    solid
    :no-auto-hide="autoHide"
    auto-hide-delay="4000"
    :no-hover-pause="true"
    no-close-button
    :toast-class="{ 'top-space': isSystemAlertEnabled }"
  >
    <template #toast-title>
      <div class="vb__toast-image">
        <img :src="imgPath" alt="toast icon" width="24" />
      </div>
      <div class="vb__toast-content">
        <div class="vb__toast-title">{{ title }}</div>
        <div
          class="vb__toast-description"
          v-if="isHTMLContent"
          v-html="description"
          @click="onHTMLClick"
        ></div>
        <div class="vb__toast-description" v-else>
          {{ description }}
        </div>
      </div>
      <span
        class="vb__toast-close"
        @click="close(id)"
        role="button"
        data-dismiss="toast"
        aria-label="Close"
      >
        <img
          :src="require(`@/assets/images/vle/close-icon.svg`)"
          alt="close"
          width="24"
        />
      </span>
    </template>
    <!-- TODO ADD Link to redirect to Progress tab-->
  </b-toast>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  props: {
    id: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    imgPath: {
      type: String,
      required: true
    },
    description: {
      type: String
    },
    autoHide: {
      type: Boolean,
      default: true
    },
    isHTMLContent: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters(["isSystemAlertEnabled"])
  },
  methods: {
    close(id) {
      this.$bvToast.hide(id);
    },
    onHTMLClick(e) {
      this.$emit("onHTMLClick", e);
    }
  }
};
</script>
<style lang="scss">
.b-toast,
.toast,
.b-toaster-slot {
  max-width: 372px !important;
}

.b-toast {
  background-color: transparent;
  border-radius: 16px !important;
  box-shadow: none;
  .toast {
    border-style: solid;
    border-width: 2px !important;
    border-color: $brand-primary-400 !important;
    border-radius: 16px !important;
    box-shadow: 0px 1px 3px 1px rgba(33, 33, 33, 0.25);
    padding: 6px 16px;
    width: 100%;
    background-color: $brand-primary-100 !important;
    font-family: $font-family;
    display: block !important;
    .toast-header {
      background-color: transparent !important;
      border: none;
      padding: 0;
      align-items: flex-start;
    }
    .toast-body:empty {
      display: none;
    }
    .vb__toast-content {
      padding: 0px 4px 0px 8px;
    }
    .vb__toast-title {
      font-size: 16px;
      line-height: 24px;
      font-weight: 500;
      letter-spacing: 0.15px;
      color: $brand-neutral-700;
    }
    .vb__toast-description {
      font-size: 14px;
      line-height: 21px;
      font-weight: 400;
      letter-spacing: 0.25px;
      color: $brand-neutral-700;
      .toast-link {
        color: $brand-primary-400;
        text-decoration: none;
        cursor: pointer;
        &:hover {
          color: $brand-primary-400;
          text-decoration: underline;
        }
      }
    }
    .vb__toast-close {
      margin-left: auto;
      width: 32px;
      min-width: 32px;
      height: 32px;
      border-radius: 50%;
      @include flex-horizontal-center;
      &:hover {
        background-color: $brand-neutral-0;
      }
    }
  }
}

.b-toaster-slot {
  #draft-toast__toast_outer,
  #publish-toast__toast_outer,
  #delete-toast__toast_outer,
  #update-toast__toast_outer,
  #report-toast__toast_outer,
  #report-response-toast__toast_outer,
  #edit-toast__toast_outer,
  #report-toast-creator__toast_outer,
  #copy-link__toast_outer,
  #publish-failed-toast__toast_outer {
    margin-top: 74px;
    margin-right: 28px;
    .top-space {
      margin-top: 114px;
    }
  }
  #toxic-language-toast__toast_outer,
  #toxic-response-toast__toast_outer {
    max-width: 344px !important;
    margin-top: 74px;
    margin-right: 8px;
    margin-left: auto;
    .top-space {
      margin-top: 114px;
    }
  }
}

#publish-toast,
#delete-toast,
#update-toast,
#copy-link {
  border-color: $brand-success-400 !important;
  background-color: $brand-success-100 !important;
}

#report-toast,
#report-response-toast,
#edit-toast,
#report-toast-creator,
#toxic-language-toast,
#toxic-response-toast {
  border-color: $brand-warning-400 !important;
  background-color: $brand-warning-100 !important;
}

#publish-failed-toast {
  border-color: $brand-error-400 !important;
  background-color: $brand-error-100 !important;
}

#copy-link__toast_outer {
  max-width: 344px !important;
  margin-left: auto;
  #copy-link {
    padding: 8px 8px 8px 16px;
    .vb__toast-content {
      padding: 0px 8px;
    }
    .vb__toast-title {
      @include body-regular($brand-neutral-700, 500);
      letter-spacing: 0.15px;
    }
  }
}
[dir="rtl"] {
  ~ .b-toaster.b-toaster-top-right .b-toaster-slot {
    margin-left: 0;
    margin-right: auto;
  }
}
</style>
