import { mapGetters } from "vuex";

function envToQueryParam(environment) {
  switch (environment) {
    case "demo":
      return "prod";
    case "testing":
      return "uat";
    default:
      return "dev";
  }
}

export default {
  methods: {
    openFeedbackForm() {
      window.open(
        this.getFeedbackUrl,
        "_blank" // <- This is what makes it open in a new window.
      );
    },
    loadUserProfile() {
      this.$keycloak.loadUserProfile().then(res => {
        this.$store.commit("SET_KEYCLOAK_USER_PROFILE", res);
      });
    }
  },
  computed: {
    ...mapGetters(["getCurrentMenuItem", "getUserProfile", "allConfig"]),
    getFeedbackUrl() {
      let url = `${this.allConfig.feedbackUrl}?app=${this.getAppName}&subject=${
        this.getSubject
      }&page_url=${encodeURIComponent(location.href)}&env=${envToQueryParam(
        this.allConfig.environment
      )}`;
      if (this.$keycloak.authenticated) {
        this.getUserProfile.email
          ? (url += `&email=${this.getUserProfile.email}`)
          : this.loadUserProfile();
      }
      return url;
    },
    getSubject() {
      let sub;
      // vle
      if (this.$route.path.includes("self-paced")) {
        if (this.getCurrentMenuItem !== "modules") {
          sub = this.getCurrentMenuItem;
        } else {
          sub = "course_outline";
        }
      } else if (this.$route.name === "my-learning") {
        sub = "my_learning";
      } else if (this.$route.name === "settings") {
        sub = "settings";
      } else if (this.$route.name === "Courses Detail") {
        sub = "course_details_page";
      } else if (
        this.$route.name === "course-discovery" ||
        this.$route.name === "SearchCourses"
      ) {
        sub = "discovery";
      } else if (this.$route.name === "Home") {
        sub = "search";
      } else if (this.$route.name === "Dashboard") {
        sub = "overall_experience";
      } else {
        sub = this.$route.name.toLowerCase();
      }

      return sub;
    },
    getAppName() {
      let name;
      if (this.$keycloak.authenticated) {
        if (this.$route.path.includes("self-paced")) {
          name = "vle";
        } else {
          name = "lxp";
        }
      } else {
        name = "landing";
      }
      return name;
    }
  }
};
